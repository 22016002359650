import * as React from 'react'
import { graphql } from 'gatsby'

import { Container, Grid } from '@mui/material';
import Breadcrumbs from '../components/Breadcrumbs';
import RichText from '../components/RichText';
import PageHeader from '../components/layout/PageHeader';
import Content from '../components/Content';
import buildMetaData from '../services/buildMetaData';
import buildOgpTags from '../services/buildOgpTags';
import Seo from '../components/Seo';
import addToSchema from '../services/addToSchema';

const ServicesPageTemplate = ({ data, pageContext }) => {
  const page = data.page || {}

  return <>
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <RichText
            sx={{
              textAlign: 'center'
            }}
            content={page.data.title.richText}
          />
        </Grid>
        <Grid item xs={12}>
          <PageHeader icon={page.data.icon} subtitle={page.data.subtitle} />
        </Grid>
      </Grid>
    </Container>
    <Content doc={page} />
  </>
}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={pageContext.url} />
}

export const query = graphql`
  query servicesPageQuery($lang: String) {
    page: prismicServicesPage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {           
        meta_title
        meta_description
        meta_keywords
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          richText
        }
        subtitle {
          richText
        }
        icon {
          alt
          gatsbyImageData(width: 100)
        }

        body {






          ... on PrismicServicesPageDataBodyService {
            id
            slice_type
            primary {
              service_show_reversed
              service {
                document {
                  ... on PrismicService {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      short_description {
                        text
                      }
                      image {
                        alt
                        gatsbyImageData(width: 700)
                      }
                    }
                  }
                }
              }
            }
          }



          ... on PrismicServicesPageDataBodyAd {
            id
            slice_type
            items {
              ad {
                document {
                  ... on PrismicAd {
                    id
                    type
                    uid
                    data {
                      title {
                        text
                      }
                      subtitle
                      url
                      left_upper_text {
                        richText
                      }
                      left_lower_text {
                        richText
                      }
                      image {
                        alt
                        gatsbyImageData(height: 255)
                      }
                    }
                  }
                  ... on PrismicBanner {
                    id
                    type
                    uid
                    data {
                      type
                      title {
                        text
                      }
                      subtitle                      
                      button_text
                      url
                      image {
                        alt
                        gatsbyImageData
                      }                      
                    }
                  }
                }
              }
            }
          }





          ... on PrismicServicesPageDataBodyCarousel {
            id
            slice_type
            items {
              slide_image {
                gatsbyImageData(width: 400)
                alt
              }
            }
            primary {
              content {
                text
                richText
              }
            }
          }




          ... on PrismicServicesPageDataBodyIntro {
            id
            slice_type
            primary {
              intro_headline {
                richText
              }
              intro_box_1_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_box_2_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_description {
                richText
              }
              intro_box_3_text {
                richText
              }
            }
          }


          ... on PrismicServicesPageDataBodyAboutUs {
            id
            slice_type
            primary {
              about_us_title {
                richText
              }
              about_us_description {
                richText
              }
              about_us_upper_left_text
              about_us_upper_right_text
              about_us_lower_left_text
              about_us_lower_right_text
            }
          }



        }
      }
    }
  }
`

export default ServicesPageTemplate